.bg-warning {
  background-color: #00a4a9 !important;
}
.border-warning {
  border-color: #00a4a9 !important;
}
.formio-component-button .btn-primary {
  background: #00a4a9 !important;
  border-color: #00a4a9 !important;
  color: #f8f9fa !important;
}
.formio-button-add-row {
  background: #00a4a9 !important;
  border-color: #00a4a9 !important;
  color: #f8f9fa !important;
  font-size: 12px !important;
}
.formio-button-add-row .fa-plus {
  font-size: 11px !important;
}
.formio-button-remove-row {
  background: none !important;
  border-color: #d2322d !important;
  color: #f8f9fa !important;
}
.formio-button-remove-row i {
  font-size: 22px;
  color: #d2322d;
}
.formio-collapse-icon {
  color: #f8f9fa !important;
}
.form-group {
  margin-bottom: 7px;
}
.formio-component-button {
  padding-top: 13px;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background: #00a4a9 !important;
}
.formio-component-emailSuggest {
  position: absolute !important;
  min-width: 23%;
}
.fa-times-circle-o:before {
  content: "\f014";
}
.formio-component-articleGrid {
  min-height: 300px;
}
/* .formio-component-datagrid {
  min-height: 300px;
} */
.formio-hidden {
  min-height: 0px !important;
}
.formio-component-studentInformation {
  min-height: 300px;
}
.formio-component-textfield .form-control {
  min-width: 100px;
}
.formio-component-purchaseCategoryId {
  padding-top: 12px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}
.formio-component-datetime {
  min-width: 200px;
}
.formio-component-datetime
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.formio-component-file {
  width: 250px;
}
.formio-component-datetime .input-group-text {
  padding: 11px 9px;
  font-size: 15px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.formio-component-file .list-group-item {
  overflow: hidden;
}
.formio-component-file .list-group-item .col-md-7 {
  overflow: hidden;
}
