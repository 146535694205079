.loader-container {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.loader-samll-container {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  // background: rgba(199, 185, 185, 0.5);
  z-index: 99;
}
.loader-screen-container {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 470px;
  // background:rgb(241, 240, 240);
  z-index: 99;
  display: flex;
  flex-direction: column;
}
.animated-loader-txt {
  animation: animatedText 0.8s ease-in-out infinite alternate;
  font-family: "Poppins";
  font-size: 1.625rem;
  letter-spacing: 0.5rem;
  color: rgb(34, 0, 112);
  margin-bottom: 5px;
}

@keyframes animatedText {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(10px);
  }
}

.animated-loader-txt-1 {
  animation-delay: 0s;
}

.animated-loader-txt-1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 50px;
  background: rgb(79, 27, 201);
  border: 4px solid rgb(79, 27, 201);
}

.animated-loader-txt-1::after {
  content: "";
  position: absolute;
  top: 0;
  right: 5px;
  height: 30px;
  transform: rotate(-30deg);
  border-radius: 50px;
  background: rgb(255, 208, 0);
  border: 4px solid rgb(255, 208, 0);
}

.animated-loader-txt-2 {
  animation-delay: 0.1s;
}

.animated-loader-txt-3 {
  animation-delay: 0.2s;
}

.animated-loader-txt-4 {
  animation-delay: 0.3s;
}

.animated-loader-txt-5 {
  animation-delay: 0.4s;
}

.animated-loader-txt-6 {
  animation-delay: 0.5s;
  font-size: 1rem;
}
