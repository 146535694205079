.App {
  font-family: sans-serif;
  text-align: center;
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}
button {
  border: none;
  padding: 8px;
  margin-right: 2px;
}
.blue {
  background-color: blue;
  color: white;
  border: none;
  padding: 8px;
}
.green {
  background-color: darkolivegreen;
  color: white;
}
.btn-toolbar {
  padding-bottom: 10px;
  /* margin: auto; */
}

.component-edit-container .float-right {
  display: none;
}
.formarea {
  padding: 15px !important;
  /* background: #6a6a6a73 !important; */
  border-radius: 8px !important;
}
.formcomponents {
  padding: 4px 2px !important;
}
.component-settings-button-remove {
  cursor: pointer !important;
}
.component-settings-button-copy {
  cursor: pointer !important;
}
.component-settings-button-paste {
  cursor: pointer !important;
}
.component-settings-button-edit-json {
  cursor: pointer !important;
}
.component-settings-button-move {
  cursor: pointer !important;
}
.component-settings-button-edit {
  cursor: pointer !important;
}
/* ::-webkit-scrollbar {
    display: none;
} */
.drag-copy {
  /* background: rgb(103, 85, 223) !important; */
  background: none;
  border: 1px solid rgb(103, 85, 223);
  cursor: pointer !important;
  color: rgb(103, 85, 223);
}
.drag-copy:hover {
  background: rgb(103, 85, 223) !important;
  color: white;
}

.formio-dialog {
  z-index: 99999999 !important;
}

.formio-dialog-content {
  z-index: 99999999 !important;
}
.formbuilder {
  margin: 0px !important;
}
.rta__autocomplete {
  z-index: 1 !important;
}
.formio-form thead {
  background: #6755df;
  color: white;
}
.formio-component-datagrid .datagrid-table,
.formio-component-datagrid .datagrid-table td,
.formio-component-datagrid .datagrid-table th {
  border: 1px solid #ddd !important;
  font-size: 12px;
}
.formcomponents .formcomponent {
  color: rgb(103, 85, 223) !important;
}
.formcomponents .formcomponent:hover {
  color: whitesmoke !important;
}

.drag-container {
  padding: 0px 5px;
  border: dotted 1px #c1c1c1;
}
.col-form-label {
  font-size: 12px;
}
.form-check-label span {
  font-size: 12px;
}
.ui.selection.dropdown {
  /* max-height: 35px !important; */
  border: 1px solid #dbdbdb !important;
  background: white !important;
}
.formio-component .ui div {
  margin-top: 2px !important;
  font-size: 12px;
}
.formio-component .nav-tabs .nav-link.active {
  margin-top: 12px !important;
}

.aira_form_builder ul {
  padding: 4px;
}

.btn-block {
  width: 100%;
}

.input-group {
  padding: 0px;
  border-bottom: 0px;
}

.input-group .form-control {
  border-radius: 10px;
}
.MuiTablePagination-root p {
  margin: 0px;
}
.formio-component-datagrid {
  overflow-x: auto;
  overflow-y: hidden;
}
.formio-component-datagrid .datagrid-table,
.formio-component-datagrid .datagrid-table td,
.formio-component-datagrid .datagrid-table th {
  white-space: nowrap;
}
.is-open .choices__list--dropdown {
  z-index: 1000;
}
.choices__list--dropdown {
  z-index: 1000;
}
.formio-component .form-control {
  font-size: 14px;
}
.required:after {
  content: none;
}
.formio-component-datetime {
  min-width: 200px;
}
.formio-component-datetime
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.formio-component-datetime .input-group-text {
  padding: 11px 9px;
  font-size: 15px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.formio-component .card-body {
}
.choices__list .choices__list--dropdown {
  position: absolute;
  z-index: 3;
}

.choices__item.choices__item--selectable {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.formio-component-SelectComponent {
  min-width: 135px;
}
.formio-component-file {
  width: 250px;
}
.formio-component-file .list-group-item {
  overflow: hidden;
}
.formio-component-file .list-group-item .col-md-7 {
  overflow: hidden;
}

/* .formio-component-DownloadFileComponent .formio-errors  {
  display: none !important;
} */