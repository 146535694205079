.footer {
  margin: 40px;
  //background: white;
  padding: 15px 0;
  text-align: right;
}
.footer p {
  margin: 0;
  //color: #200e32;
  font-size: 14px;
}
