html {
  scroll-behavior: smooth;
}
:root {
  --bs-yellow: #00a4a9;
}
body {
  min-width: 400px;
}

.escenteralign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.scout__bar-brand-logo {
  display: none !important;
}

.ghostNodeCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 1px 1px lightgrey;
  height: 60px;
  cursor: pointer;
  width: 60px;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
  padding-right: 0px !important ;
  scroll-behaviour: smooth;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.Toastify {
  position: absolute;
  z-index: 9999999 !important;
}

.Toastify__toast-body {
  word-break: break-word;
}

/* Query builder React select css */
.es_rselect_qb__single-value,
.es_rselect_qb__placeholder {
  position: static;

  transform: none;
  max-width: none;
}

html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.nodeLabel {
  font-weight: 500;
  font-size: 7px;
  width: 50px;
  font-family: Poppins;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-flow {
  /* border: 2px solid #fa0000; */
}

.react-flow__node {
  //z-index: -1 !important;
}

.react-flow__renderer {
  /* border: 2px solid #001973; */
}

.react-flow__node .selected {
  background-color: orange;
}

.Toastify__toast-container {
  z-index: 9999 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.swal2-popup {
  font-family: "Poppins";
}

.swal2-actions {
  font-size: 14px;
}

.swal2-image {
  height: 125px !important;
  width: 225px !important;
}

.swal2-html-container {
  font-size: 1rem !important;
}

/* width*/
*::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.4rem;
}

/* Track*/
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5rem #cccccc;
  border-radius: 1rem;
}

/* Handle*/
*::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}

/* Handle on hover*/
*::-webkit-scrollbar-thumb:hover {
  background-color: #adadad;
}
.nav-link span {
  font-size: 15px !important;
}
.errorText::before {
  display: inline !important;
  content: "⚠ " !important;
  font-size: 14px;
  vertical-align: middle;
}
.errorText {
  margin-top: 5px !important;
  margin-left: 5px !important;
  color: #f44336 !important;
}
.nav-icon {
  height: 25px !important;
  width: 25px !important;
}
.iframe_content {
  padding: 1px;
  background-color: #f1f1f1;
}

.animation {
  overflow: hidden !important;
}

.iframe_content .makeStyles-logo-5 {
  display: none !important;
}
.jodit-status-bar .jodit-status-bar__item.jodit-status-bar__item-right {
  display: none !important;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 1500;
}

.MuiInputBase-input .MuiOutlinedInput-input {
  font-size: 12px;
}
.MuiInputBase-input .MuiSelect-select {
  font-size: 12px;
}
.required:after {
  content: " *";
  color: red;
}

.imagehover:hover {
  cursor: pointer;
  box-shadow: #000000;
}

.field-container {
  display: flex;
  padding: 12px 10px 0;
  position: relative;
  width: 50%;
}

.field-container.focused:before {
  opacity: 1;
  transform: scaleX(1);
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: border, opacity, transform;
  z-index: 11; /* Here */
}

.field-container.focused label {
  font-size: 15px;
  opacity: 1;
  pointer-events: auto;
  top: 0;
  z-index: 10; /* Here */
}

.field-container.focused .select-form-control .options-form-control {
  opacity: 1;
  visibility: visible;
}

.field-container:before,
.field-container:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  transition: border 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0s cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
  will-change: border, opacity, transform;
}

.field-container:before {
  background: #000;
  height: 2px;
  opacity: 0;
  transform: scaleX(0.12);
}

.field-container:after {
  background: #ccc;
  height: 1px;
}

.field-container label {
  color: #ccc;
  font-size: 21px;
  font-weight: 500;
  pointer-events: none;
  position: absolute;
  top: 25px;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 0.3s;
}

.field-container .select-form-control {
  display: flex;
  position: relative;
  width: 100%;
}

.field-container input {
  background: none;
  border: none;
  color: #000;
  cursor: text;
  display: block;
  flex: 1;
  font-size: 21px;
  font-weight: 500;
  height: 56px;
  line-height: 56px;
  margin: 0;
  min-width: 100px;
  outline: none;
  padding: 0;
  text-rendering: auto;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: font-size, padding-top, color;
  word-spacing: normal;
  -webkit-appearance: textfield;
  -webkit-rtl-ordering: logical;
  -webkit-writing-mode: horizontal-tb !important;
}

.field-container .select-form-control .options-form-control {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 23px 71px 0 rgba(204, 204, 204, 0.09);
  left: -20px;
  opacity: 0;
  padding-top: 90px;
  position: absolute;
  right: -20px;
  top: -22px;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  z-index: 2; /* Here */
}

.field-container .select-form-control .options-form-control ul {
  list-style-type: none;
  max-height: 200px;
  overflow: auto;
  padding: 0 0 10px;
  margin: 0;
}

.field-container .select-form-control .options-form-control ul li {
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 21px;
  font-weight: 500;
  line-height: 2.12;
  padding: 0 20px;
  z-index: -1; /* Here */
  margin: 0;
}

.field-container .select-form-control .options-form-control ul li:hover {
  background: #ccc;
}
